import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import BlogPost from "../components/blog-post";
import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
} from "@heroicons/react/solid";

const BlogList = ({ pageContext, data }) => {
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "/" : `/page${currentPage - 1}/`;
  const nextPage = `/page${currentPage + 1}/`;

  const layoutProps = {
    nav: "blog",
  };
  if (isFirst) {
    // we are using page 1 of the blog listing as the homepage
    // let's add the canonical here
    layoutProps.pageCanonical = data.site.siteMetadata.siteUrl;
  }

  return (
    <Layout {...layoutProps}>
      {data.allMdx.edges.map(({ node }) => (
        <BlogPost key={node.id} node={node} isExcerpt={true} />
      ))}

      <div>
        {!isLast && (
          <Link
            to={nextPage}
            className="py-2 px-4 inline-block border rounded-lg text-black font-semibold bg-gray-200 hover:no-underline border-opacity-25"
            rel="prev"
          >
            <ArrowCircleLeftIcon className="h-5 w-5 -mt-1 inline-block text-black" />{" "}
            Older Entries
          </Link>
        )}
        {!isFirst && (
          <Link
            to={prevPage}
            className="float-right py-2 px-4 inline-block border rounded-lg text-black font-semibold bg-gray-200 hover:no-underline border-opacity-25"
            rel="next"
          >
            Newer Entries{" "}
            <ArrowCircleRightIcon className="h-5 w-5 -mt-1 inline-block text-black" />
          </Link>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            tags
          }
          slug
          body
        }
      }
    }
  }
`;

export default BlogList;
